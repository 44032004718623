/*
 * @Author: 张博洋
 * @Date: 2021-07-15 15:31:11
 * @LastEditTime: 2021-09-08 14:23:02
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /hospital-h5/src/mixin/Form.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
import {
  // Cell,
  // CellGroup,
  Checkbox,
  Form,
  Field,

  CheckboxGroup,
  Stepper,
  Switch,
  Radio,
  RadioGroup
} from 'vant';

export default {
  methods: {
    appCheckFormAction (o) { //检查表单验证
      let obj = this.$.extend(true, {
        body: 'body', //refresh 的ref名称
        form: 'form', //vanfrom 的ref名称
        offset: -50, //scroll便宜
        autoFocus: true, //错误是否自动聚焦
      }, o);
      return this.$refs[obj.form].validate().catch((error) => { //

        if (error && error.length > 0) {
          // error.forEach(i => {
          //   if(i.name === '就诊人')
          // })
          let a = error[0];
          this.$.toast.warning((a.name || '') + a.message)
        } else {
          this.$.toast.warning('表单填写有误，请检查！');

        }
        try {
          let $body = this.$refs.form.$refs.downRefresh;
          let $error = $body.querySelector('.van-field--error');
          console.log($body, $error)
          this.$scrollTo($error, 300, {
            offset: obj.offset,
            container: $body,
            onDone () {

              if (obj.autoFocus) {
                console.log($error)
                $error.querySelector('.van-field__control').focus();
              }
            }
          })
        } catch (error) {
          if (obj.autoFocus) {
            // console.log(errName)
            // console.log(this.$refs.form.$el.$refs[errName])
            // this.$refs.form.$el.$refs[errName].focus()
            // this.$refs.form.$el.querySelector('.van-field__control').focus();
          }
        }

        return Promise.reject(error)
      })
    },
  },
  components: (() => {
    let obj = {};
    // obj[Cell.name] = Cell;
    // obj[CellGroup.name] = CellGroup;
    obj[Form.name] = Form;
    obj[Field.name] = Field;
    obj[Checkbox.name] = Checkbox;
    obj[CheckboxGroup.name] = CheckboxGroup;
    obj[Stepper.name] = Stepper;
    obj[Switch.name] = Switch;
    obj[Radio.name] = Radio;
    obj[RadioGroup.name] = RadioGroup;
    return obj;
  })(),
}
